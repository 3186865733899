<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="refMortgage" backlink="/refinance-mortgage/obligations" />
      <div class="content">
        <div class="content__container">
          <Question
            service="refMortgage"
            name="problem"
            question="question_4"
            successUrl="/refinance-mortgage/about-problems"
            negativeUrl="/refinance-mortgage/reason"
            :droppedSteps="['/refinance-mortgage/about-problems']"
            :negativeMissedSteps="['/refinance-mortgage/about-problems']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'
export default {
  name: 'Refinance_mortgage_problems',
  components: { Backlink, Question }
}
</script>
